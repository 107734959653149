<template>
  <div class="path-download-diagram-modal d-flex align-items-center flex-column">
    <button class="btn close-btn" @click="closeModal">
      <Icon name="close" size="24" />
    </button>
    <div class="pdd-modal-nav d-flex justify-content-center">
      <div class="title fs-h4">選擇檔案</div>
    </div>
    <div class="pdd-modal-content row">
      <div class="col-12 col-md-6 d-flex flex-md-column flex-nowrap">
        <div class="diagram">
          <div class="title fs-body-14">JPG：方形｜白色背景</div>
          <journey-drawer v-if="drawerDataReady" style="width: 100%" ref="rcanvas1" :showInfo="true" :journey="pathInfo" :day-index="-1" :hide-blank-milestones="drawConfig.hideBlankMilestones" :config="drawConfig.config" :title="pathInfo.name"></journey-drawer>
        </div>
        <div class="btn-list d-flex flex-column flex-md-row justify-content-md-center">
          <div class="btn-item">
            <button class="btn common-circle-btn dark" @click="imgCopy('rcanvas1')">
              <img :src="imageSrc.svgPasteWhite" alt="copy btn">
            </button>
          </div>
          <div class="btn-item">
            <button class="btn common-circle-btn dark" @click="imgDl('rcanvas1')">
              <img :src="imageSrc.svgDownloadWhite" alt="download btn">
            </button>
          </div>
          <div class="btn-item">
            <button class="btn common-circle-btn dark" @click="imgShare('rcanvas1')">
              <img :src="imageSrc.svgShareWhite" alt="share btn">
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex flex-md-column">
        <div class="diagram">
          <div class="title fs-body-14">PNG：方形｜無背景色</div>
          <journey-drawer v-if="drawerDataReady" style="width: 100%" ref="rcanvas2" :showInfo="false" :journey="pathInfo" :day-index="-1" :hide-blank-milestones="drawConfig.hideBlankMilestones" :config="drawConfig.config" :title="pathInfo.name"></journey-drawer>
        </div>
        <div class="btn-list d-flex flex-column flex-md-row justify-content-md-center">
          <div class="btn-item">
            <button class="btn common-circle-btn dark" @click="imgCopy('rcanvas2')">
              <img :src="imageSrc.svgPasteWhite" alt="copy btn">
            </button>
          </div>
          <div class="btn-item">
            <button class="btn common-circle-btn dark" @click="imgDl('rcanvas2')">
              <img :src="imageSrc.svgDownloadWhite" alt="download btn">
            </button>
          </div>
          <div class="btn-item">
            <button class="btn common-circle-btn dark" @click="imgShare('rcanvas2')">
              <img :src="imageSrc.svgShareWhite" alt="share btn">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

import JourneyDrawer from '@/components/common/JourneyDrawer';

import svgCloseRoundLight from "@/assets/images/icon/Close_round_light.svg"
import svgClose from "@/assets/images/icon/svg/close.svg"
import svgPasteWhite from "@/assets/images/icon/paste-white.svg"
import svgDownloadWhite from "@/assets/images/icon/download-white.svg"
import svgShareWhite from "@/assets/images/icon/share-white.svg"

export default {
  name: 'PathDownloadDiagram',
  data() {
    return {
      readInfoBusyName: 'READINFOBUSY',

      imageSrc: {
        svgCloseRoundLight: svgCloseRoundLight,
        svgClose: svgClose,
        svgPasteWhite: svgPasteWhite,
        svgDownloadWhite: svgDownloadWhite,
        svgShareWhite: svgShareWhite,
      },
      drawConfig: {
        hideBlankMilestones: false,
        config: null,
      },
      drawerDataReady: false,
      
    };
  },
  props: {
    pathSerial: {
      required: true,
      default: null,
    },
    pathInfo: {
      required: true,
      default: {},
    },
    windowWidth: {
      required: true,
      default: 0,
    },
  },
  components: {
    JourneyDrawer,
  },
  computed: {
    svgCloseBtn: function() {
      return this.windowWidth < 768 ? svgClose : svgCloseRoundLight;
    },
	},
  watch: {
    
  },
  async mounted () {
    await this.refresh();
	},
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),

    refresh: async function() {
      await this.$store.dispatch('appendComponentBusy', this.readInfoBusyName);
      try {
        const MtPath = await this.$store.dispatch('api/getPathDetailPromise', this.pathSerial);
        console.log(MtPath)
        this.drawConfig.hideBlankMilestones = MtPath.path.tags.indexOf('百岳') !== -1;
        const config = await this.$store.dispatch('api/getPathDrawingConfigPromise', this.pathSerial);
        if (config !== null) {
          this.drawConfig.config = Object.assign({}, config);
        }
        this.drawerDataReady = true;
      } catch (error) {
        await this.$store.dispatch('appendErrorMsg', error.toString());
      } finally {
        await this.$store.dispatch('clearComponentBusy', this.readInfoBusyName);
      }
    },
    closeModal() {
      this.$emit('close-modal');
    },
    async imgCopy(e) {
      try {
        const _this = this;
        const makeImagePromise = async () => {
          const data = await _this.$refs[e].toBlobAsync();
          return data;
        }
        navigator.clipboard.write([
          new window.ClipboardItem({
            "image/png": makeImagePromise()
          }),
        ]).then(() => {
          this.$store.dispatch('appendErrorMsg', '圖片已複製，您可以到 Line 或 Instagram 等平台中直接貼上。');
          // alert('圖片已複製，您可以到 Line 或 Instagram 等平台中直接貼上。');
        }).catch((err) => {
          console.error(err);
          this.$store.dispatch('appendErrorMsg', '複製錯誤，您所使用的瀏覽器可能阻擋了複製的功能');
          // alert('複製錯誤，您所使用的瀏覽器可能阻擋了複製的功能');
        });
      } catch (error) {
        alert(error.toString());
      }
    },
    imgDl(e) {
      const dataurl = this.$refs[e].toDataURL();
      const link = document.createElement('a');
      link.download = `${this.pathInfo.name}_joyhike.png`;
      link.href = dataurl;
      link.click();
    },
    async imgShare(e) {
      try {
        const blob = await this.$refs[e].toBlobAsync();
        const filesArray = [
          new File(
              [blob],
              'joyhike.png',
              {
                type: "image/png",
                lastModified: new Date().getTime()
              }
          )
        ];
        const shareData = {
          files: filesArray,
        };
        await navigator.share(shareData);
      } catch (error) {
        console.error(error);
        await this.$store.dispatch('appendErrorMsg', '分享失敗，您的瀏覽器可能阻擋了分享的相關權限');
        // alert('分享失敗，您的瀏覽器可能阻擋了分享的相關權限');
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/basic";
  .path-download-diagram-modal {
    position: relative;
    max-width: 100%;
    padding: 36px 0;
    @include smaller-than-medium {
      padding: 28px 0;
    }
    .close-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      padding: 0;
      @include smaller-than-medium {
        top: 28px;
        left: 16px;
        right: initial;
        width: 24px;
        object-fit: cover;
      }
    }
    .pdd-modal-nav {
      width: 100%;
      padding-bottom: 36px;
      @include smaller-than-medium {
        padding-bottom: 28px;
      }
      @include smaller-than-medium {
        box-shadow: 0px -1px 0px 0px #F3F3F3 inset;
      }
      .title {
        position: relative;
        padding-bottom: 8px;
        @include smaller-than-medium {
          padding-bottom: 0;
          &:after {
            display: none;
          }
        }
        &:after {
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          background: $color-dark;
          width: 100%;
          height: 3px;
          transform: scaleX(1.25);
        }
      }
    }
    .pdd-modal-content {
      width: 100%;
      padding: 0 21px;
      @include smaller-than-medium {
        padding-top: 24px;
        .col-12:not(:last-child) {
          margin-bottom: 28px;
        }
      }
      @include bigger-than-large {
        padding: 0 45px;
        &.row {
          margin-left: -23px;
          margin-right: -23px;
        }
        .col-md-6 {
          padding-left: 23px;
          padding-right: 23px;
        }
      }
      &.row {
        margin-left: -7px;
        margin-right: -7px;
      }
      .col-12, .col-md-6 {
        padding-left: 7px;
        padding-right: 7px;
      }
      .title {
        margin-bottom: 16px;
      }
    }
    &:deep .route-canvas {
      border: 1px solid $color-black-300;
      border-radius: 16px;
      overflow: hidden;
      canvas {
        width: 100%;
        height: 100%;
      }
    }
    .diagram, .btn-list {
      @include smaller-than-medium {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .btn-list {
      margin-top: 20px;
      @include smaller-than-medium {
        margin-top: 36px;
        .btn-item {
          padding: 0;
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
      .btn-item {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
</style>
