<template>
  <div class="column-layout row" :class="rowClassObject">

<!--    <div class="col-panel col-12 col-md-8 order-1 order-md-0" v-if="columnCount === 2">-->
<!--      <slot name="column-one"></slot>-->
<!--    </div>-->
<!--    <div class="col-panel col-12 col-md-4 order-0 order-md-1 desktop-sticky" v-if="columnCount === 2">-->
<!--      <slot name="column-two"></slot>-->
<!--    </div>-->

<!--    <div class="col-panel col-12 col-lg-3 order-0 order-lg-0" v-if="columnCount === 3">-->
<!--      <slot name="column-one"></slot>-->
<!--    </div>-->
<!--    <div class="col-panel col-12 col-lg-4 order-2 order-lg-1" v-if="columnCount === 3">-->
<!--      <slot name="column-two"></slot>-->
<!--    </div>-->
<!--    <div class="col-panel col-12 col-lg-5 order-1 order-lg-2" v-if="columnCount === 3">-->
<!--      <slot name="column-two"></slot>-->
<!--    </div>-->

    <div class="col-panel col-12" :class="colOneClassObject">
      <slot name="column-one"></slot>
    </div>
    <div class="col-panel col-12" :class="colTwoClassObject">
      <slot name="column-two"></slot>
    </div>
    <div class="col-panel col-12" :class="colThreeClassObject" v-if="threeColumn" ref="resizableElement" @transitionend="handleTransitionEnd">
      <slot name="column-three"></slot>
    </div>

  </div>
</template>

<script>

export default {
  name: 'ColumnLayout',
  props: {
    routerName: {
      required: false,
      default: '',
    },
    mapScale: {
      required: false,
      default: false,
    },
    mapScaleFunction: {
      required: false,
      default: null,
    }
  },
  data() {
    return {

    };
  },
  created() {
  },
  computed: {
    memberJoin: function () {
      return this.routerName === 'GroupDetailMemberJoin';
    },
    twoColumn: function () {
      return this.routerName === 'Account';
    },
    threeColumn: function () {
      return this.routerName === 'PathDetail';
    },
    rowClassObject: function () {
      return {
        'flex-md-row': this.twoColumn,
        'flex-lg-row': this.threeColumn,
        'scale-active': this.mapScale === true,
      }
    },
    colOneClassObject: function () {
      return {
        'col-md-8': this.twoColumn,
        'order-1': this.twoColumn,
        'order-md-0': this.twoColumn,
        'col-lg-3': this.threeColumn || this.memberJoin,
        'order-0': this.threeColumn,
        'order-lg-0': this.threeColumn,
        'common-transition': this.threeColumn,
      }
    },
    colTwoClassObject: function () {
      return {
        'col-lg-9': this.memberJoin,
        'col-md-4': this.twoColumn,
        'order-0': this.twoColumn,
        'order-md-1': this.twoColumn,
        'desktop-sticky': this.twoColumn,
        'col-lg-4': this.threeColumn,
        'order-2': this.threeColumn,
        'order-lg-1': this.threeColumn,
      }
    },
    colThreeClassObject: function () {
      return {
        'col-lg-5': this.threeColumn,
        'order-1': this.threeColumn,
        'order-lg-2': this.threeColumn,
        'common-transition': this.threeColumn,
      }
    },
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    handleTransitionEnd(event) {
      if (event.target === this.$refs.resizableElement) {
        this.mapScaleFunction();
      }
    }
  }

}
</script>

<style lang="scss">
  @import "src/assets/scss/columnLayout";
</style>
